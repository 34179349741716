.ttd__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a b";
  gap: 4rem;
  align-items: center;
}

.ttd__grid.reverse {
  grid-template-areas: "b a";
}

.ttd__grid > *:nth-child(1) {
  grid-area: a;
}

.ttd__grid > *:nth-child(2) {
  grid-area: b;  
}

@media screen and (max-width: 767px) {
  .ttd__grid {
    display: flex;
    flex-direction: column;
  }
}