/* @font-face{
    font-family:"Copperplate Classic";
    src:url("/fonts/05e69146-1dd5-43da-aee8-7969240f2952.woff2") format("woff2"),url("/fonts/ae114ce8-55d4-46ef-b288-fbe730f5e5c0.woff") format("woff");
    font-display: swap;
} */

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #555E1E;
  font-family: 'Copperplate Classic', sans-serif;
  margin: 0
}

/* Use span or div if you don't need this spacing */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6,
* + p {
  margin-top: 1em;
}

.text-serif {
  font-family: 'Taviraj', serif;
}

.text-accent {
  font-family: 'Copperplate Classic', sans-serif;
}

.font-expanded {
  font-stretch: expanded;
}

.text-responsive {
  font-size: clamp(100%, 3vw, 150%);
}