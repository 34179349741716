.navbar__bg_container {
    position: fixed;
    width: 100%;
    z-index: 10;
    transform: translateY(-100%);
    overflow: hidden;
    transition: transform ease-in 300ms;
    background: white;
}

.navbar__bg_container.visible {
    transform: translateY(0);
    overflow: visible;
}

:not(.mobile_nav__container).navbar__link > a {
    padding-top: 2rem;
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
}

.mobile_nav__container {
    transition: transform ease-in 300ms;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 300px;

    transform: translate(-100%, 0);
}

.mobile_nav__bottom {
    transition: transform ease-in 300ms;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    transform: translate(0, 100%);
}

.mobile_nav__container.visible,
.mobile_nav__bottom.visible {
    transform: translate(0, 0);
}

.mobile_nav__container > * {
    margin-bottom: 1em;
}



.navbar__logo__width {
    width: 11rem;
}

.navbar__link > a.navbar__logo {
    padding-top: 0;
}
