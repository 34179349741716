.component-modal {
    position: relative;
    left: 100%;
    transition: left ease-in 300ms;
    
    width: 100%;
}

.component-modal.animate-bottom {
    left: 0;
    transition: top ease-in 300ms;
    top: 100%;
}

.component-modal-backdrop.no-animate > .component-modal {
    transition: none;
}

.component-modal {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.component-modal.active {
    position: relative;
    left: 0;
}

.component-modal.animate-bottom.active {
    left: 0;
    top: 0;
}

.component-modal-backdrop {
    overflow: auto;
    height: 100%;
    transition: visibility ease-in 300ms;
    visibility: hidden;
    display: flex;
}

.component-modal-backdrop.no-animate {
    transition: none;
}

.component-modal-backdrop.active {
    visibility: visible;
}
